

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, button, submit { border:none; } 


body {
  margin: 0;
  padding: 0;
  font-family: 'CircularStd', Roboto, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {outline:none !important}

.btn:focus,.btn:active {
  outline: none !important;
  box-shadow: none;
}

button:focus {
  outline:none;
  box-shadow: none;
}

a {
  text-decoration: none;
}